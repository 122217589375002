import Routing from './Routing.js';
import './styles/App.css';
import './styles/Legend.css';


function App() {

  return (
    <div className='App'>
      <div><Routing /></div>
    </div>
  );
}

export default App;

